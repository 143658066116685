import React from "react";
import PageMeta from "../components/PageMeta";

export default function FourOhFourPage() {
  return (
    <div className="col3">
      <PageMeta title="404 - not found" path="404" />
      <h1>Page not found</h1>
      <p>Oops! The page your are looking for has been removed or relocated.</p>
    </div>
  );
}
